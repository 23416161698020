<template>
  <b-container>
    <Breadcrumbs :key="bcKey" />

    <div class="category-listing-wrap">
      <SidebarComponent :isActive="isActive" @close-sidebar="closeSidebar" />

      <div class="category-listing">
        <div class="category-listing-inner">
          <h1 class="page-heading category-heading main-category-title">
            {{ category.name }}
          </h1>

          <template>
            <div class="row product-item-row">
              <div
                v-for="category in category.children"
                :key="'category' + category.id"
                class="col col-6 col-sm-2 col-md-2 col-xl-2 product-item-type"
              >
                <b-link :to="`/${category.url_path}/`">
                  <b-img
                    :src="category.image == null ? noImage : category.image"
                    :alt="category.name"
                  />
                  <div class="product-item-name">
                    <h4>{{ category.name }}</h4>
                  </div>
                </b-link>
              </div>
            </div>
            <div class="hiddenElement" ref="filterButton"></div>
            <div class="tab-mob-show" :class="stickyClass">
              <div
                class="pb-2 pt-2 filter-result text-center text-white bg-primary filter-btn-wrap"
              >
                <span class="filter-icon"
                  ><font-awesome-icon icon="filter"
                /></span>
                <span class="filter-txt pl-2" @click="openSidebar"
                  >{{ $t("search_help") }} {{ totalProducts }}
                  {{ $t("result") }}</span
                >
              </div>
            </div>
            <CategorySlider
              :category="category.id"
              :borderClass="getBorderCat"
            />
            <FilterComponent />
            <CmsBlockSimple
              :identifier="categoryCmsBlock.identifier"
              :content="categoryCmsBlock.content"
              v-if="categoryCmsBlock"
              class="action-banner"
            />
            <div class="category-bestseller-wrap">
              <h3 class="category-heading">{{ $t("best_seller") }}</h3>
              <div class="catergory-page-slider">
                <CarouselComponent
                  v-if="bestsellersProducts.length"
                  v-bind:productData="bestsellersProducts"
                  v-bind:settings="CAROUSEL_SETTING_FOURTH"
                />
              </div>
            </div>
            <div
              v-html="categoryDescription"
              class="product-page-description products-des-space"
              v-if="categoryDescription"
            ></div>
          </template>

          <div
            v-html="category.extra_description"
            class="product-page-description pt-0 pb-0"
            v-if="category.extra_description"
          ></div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import SidebarComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/SidebarComponent";
// import CategoryOverview from "@/esf_utrecht_bankxl/core/components/products/category-product/CategoryOverview.vue";
import CarouselComponent from "@/esf_utrecht_bankxl/core/components/core/CarouselComponent";
import FilterComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/FilterComponent";
import { CAROUSEL_SETTING_FOURTH } from "@/esf_utrecht_bankxl/assets/constant.js";
import CategorySlider from "@/esf_utrecht_bankxl/core/components/products/category-product/CategorySlider.vue";
import noImage from "@/esf_utrecht_bankxl/assets/images/no_image_hxl.png";
import CmsBlockSimple from "@/esf_utrecht_bankxl/core/components/products/category-product/CategoryBlockSimple.vue";

export default {
  name: "CategoryComponent",
  components: {
    Breadcrumbs,
    SidebarComponent,
    CarouselComponent,
    FilterComponent,
    // CategoryOverview,
    CategorySlider,
    CmsBlockSimple,
  },
  computed: {
    bestsellersProducts() {
      return this.$store.getters["category/getBestSellersSimple"];
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    showProducts() {
      if (
        this.category.display_mode === "PRODUCTS" ||
        this.category.display_mode === "PRODUCTS_AND_PAGE"
      ) {
        return true;
      }
      return false;
    },
    totalProducts() {
      return this.$store.getters["category/getTotalProducts"];
    },
    categoryDescription() {
      let description = this.category.description;
      if (description) {
        description = description
          .replace(/<b-link(.*?)to=/g, "<a$1href=")
          .replace(/<\/b-link>/g, "</a>");
      }
      if (description == "<p><br></p>") {
        return "";
      }
      return description;
    },
    categoryCmsBlock() {
      return this.category.cms_block;
    },
    getBorderCat() {
      if (this.category.children_count == 0) {
        return "no-top-border";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      isActive: false,
      title: "ettafel",
      CAROUSEL_SETTING_FOURTH,
      noImage,
      bcKey: 0,
      stickyClass: "nonStickyButton",
      lastScrollPosition: 0,
    };
  },
  provide() {
    return {
      openSidebar: this.openSidebar,
      closeSidebar: this.closeSidebar,
    };
  },
  destroyed() {
    this.$store.commit("category/setLoadProducts", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    openSidebar() {
      this.isActive = true;
    },
    closeSidebar() {
      this.isActive = false;
    },
    handleScroll() {
      var top = this.$refs.filterButton.getBoundingClientRect().top;
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (
        Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 &&
        currentScrollPosition < this.lastScrollPosition
      ) {
        this.stickyClass = "top60";
      } else {
        this.stickyClass = "";
      }
      this.lastScrollPosition = currentScrollPosition;
      if (top <= 0) {
        this.stickyClass += " stickyButton";
      } else {
        this.stickyClass += " nonStickyButton";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.bestsellersProducts.length == 0) {
      this.$store.dispatch("category/loadBestSellers");
    }
  },
};
</script>
